import {DefaultLanguageCode} from "@constants/Enums";

export default {
    settings: {
        lang: DefaultLanguageCode
    },
    emptyObj: {},
    emptyArr: [],
    globalInfo: {
        isConnected: false,
        session:"",
        lobbyUrl: "",
        detectNewLogin: false
    },
    totalBets: 0,
    winnerList: [], //getWinnerList(),
    history: {
        data: [],
        hasNext: false,
    },
    odds: {
        0: undefined,
        1: undefined,
        3: undefined,
        4: undefined,
    },
    loadInfo: {
      "A": null,
      "B": null,
      "prediction-2":"",
      "prediction-5":""
    },
    userBetList: [],

    cards: {
        playerA: [],
        playerB: [],
    },
    EnabledBetAreas: {
        "ABack": true,
        "ALay": true,
        "APair": true,
        "BBack": true,
        "BLay": true,
        "BPair": true
    },
    timeTicker: {
        diff: 0,
        start: 0,
        end: 0
    },
    gameHistory: {
        history: [],
        APercentage: 0,
        BPercentage: 0,
        DrawPercentage: 0,
    },
    userInfo: {
        Balance: 0,
        Currency: "",
        Id: "",
        Image: null,
        Name: ""
    },
    limit: {
        Max: 0,
        Min: 0,
        Nominal: []
    },
    selectedBoard: {
        board: -1,
        odd:0
    },
    inactivity: {
        count : 0,
        suspend: false
    }
}

function getWinnerList() {
    let result = [];
    for (let i = 0; i < 100; i++) {
        result.push({
            PlayerName: (Math.random() + 1).toString(36).substring(Math.random() * 10) + i,
            WonAmount: Math.round(Math.random() * 100000)
        })
    }
    return result
}